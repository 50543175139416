// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .SimpleMentionEditor_editor__29hnQ {
    box-sizing: border-box;
    cursor: text;
    padding: 16px;
    background: #fcfcfd;
  }
  
  .SimpleMentionEditor_editor__29hnQ .public-DraftEditor-content {
    min-height: 100px;
  }

  .SimpleMentionEditor_commentReply__DWwyY {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
    width: 80%;
  }

  .SimpleMentionEditor_commentReplyNew__Tbpot {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 2em;
    /* box-shadow: inset 0px 1px 8px -3px #ABABAB; */
    background: #fefefe;
    width: 100%;
  }
  
  .SimpleMentionEditor_commentReply__DWwyY .public-DraftEditor-content {
    min-height: 15px;
   
  }
  `, "",{"version":3,"sources":["webpack://./src/commonDashboardComp/SimpleMentionEditor.module.css"],"names":[],"mappings":";EACE;IACE,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,sBAAsB;IACtB,sBAAsB;IACtB,YAAY;IACZ,aAAa;;IAEb,kBAAkB;IAClB,0CAA0C;IAC1C,mBAAmB;IACnB,UAAU;EACZ;;EAEA;IACE,sBAAsB;IACtB,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,gDAAgD;IAChD,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,gBAAgB;;EAElB","sourcesContent":["\n  .editor {\n    box-sizing: border-box;\n    cursor: text;\n    padding: 16px;\n    background: #fcfcfd;\n  }\n  \n  .editor :global(.public-DraftEditor-content) {\n    min-height: 100px;\n  }\n\n  .commentReply {\n    box-sizing: border-box;\n    border: 1px solid #ddd;\n    cursor: text;\n    padding: 16px;\n    \n    margin-bottom: 2em;\n    box-shadow: inset 0px 1px 8px -3px #ABABAB;\n    background: #fefefe;\n    width: 80%;\n  }\n\n  .commentReplyNew {\n    box-sizing: border-box;\n    border: 1px solid #ddd;\n    cursor: text;\n    padding: 10px;\n    border-radius: 10px;\n    margin-bottom: 2em;\n    /* box-shadow: inset 0px 1px 8px -3px #ABABAB; */\n    background: #fefefe;\n    width: 100%;\n  }\n  \n  .commentReply :global(.public-DraftEditor-content) {\n    min-height: 15px;\n   \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": `SimpleMentionEditor_editor__29hnQ`,
	"commentReply": `SimpleMentionEditor_commentReply__DWwyY`,
	"commentReplyNew": `SimpleMentionEditor_commentReplyNew__Tbpot`
};
export default ___CSS_LOADER_EXPORT___;
