// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch_lang {
  background: #a9ce46;
  width: 120px;
  text-align: center;
  right: 13px;
  top: 6px;
  color: #fff;
  padding: 3px;
  font-size: 16px;
  font-weight: 500;
  z-index: 999;
}
.langParent{
  text-align: right;
  padding-bottom: 10px;
  position: absolute;
  right: 3%;
  top: 5px;
  
}
.switch_dd a{
  display: flex;
  align-items: center;
  justify-content: center;
}
.switch_dd .dropdown-menu {
  min-width: 105px;
  padding: 0px;
}
.switch_dd .dropdown-menu a {
  border: 1px solid #a9ce4614;
  text-align: center;
  font-size: 15px;
}
.switch_dd .dropdown-menu a:hover{
  background: #a9ce46;
  color: white;
}

.switch_dd{
  background:#a9ce46;
  width: 110px;
  text-align: center;
  color: #fff;
  padding: 3px;
  font-size: 16px;
  font-weight: 500;
  z-index: 999;
  border-radius: 8px;
}

@media screen and (max-width: 767px) {
  .switch_lang {
    background: #ffffff;
    color: #a9ce46;
  }  
}
`, "",{"version":3,"sources":["webpack://./src/components/switchLanguage/switchLanguage.style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,kBAAkB;EAClB,SAAS;EACT,QAAQ;;AAEV;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE;IACE,mBAAmB;IACnB,cAAc;EAChB;AACF","sourcesContent":[".switch_lang {\n  background: #a9ce46;\n  width: 120px;\n  text-align: center;\n  right: 13px;\n  top: 6px;\n  color: #fff;\n  padding: 3px;\n  font-size: 16px;\n  font-weight: 500;\n  z-index: 999;\n}\n.langParent{\n  text-align: right;\n  padding-bottom: 10px;\n  position: absolute;\n  right: 3%;\n  top: 5px;\n  \n}\n.switch_dd a{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.switch_dd .dropdown-menu {\n  min-width: 105px;\n  padding: 0px;\n}\n.switch_dd .dropdown-menu a {\n  border: 1px solid #a9ce4614;\n  text-align: center;\n  font-size: 15px;\n}\n.switch_dd .dropdown-menu a:hover{\n  background: #a9ce46;\n  color: white;\n}\n\n.switch_dd{\n  background:#a9ce46;\n  width: 110px;\n  text-align: center;\n  color: #fff;\n  padding: 3px;\n  font-size: 16px;\n  font-weight: 500;\n  z-index: 999;\n  border-radius: 8px;\n}\n\n@media screen and (max-width: 767px) {\n  .switch_lang {\n    background: #ffffff;\n    color: #a9ce46;\n  }  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
