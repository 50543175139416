// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#cfContainer{
    background-color: #fff;
}

#cfContainer *{
    font-family: 'gill sans' !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

#cfContainer label{
    font-size: 22px;
}

#cfContainer select{
    appearance: auto;
}
#cfContainer select,
#cfContainer input[type='text'],
#cfContainer textarea{
    padding: 7px 9px;
}

#cfContainer input[type='text']::placeholder,
#cfContainer textarea::placeholder{
    font-size: 16px;
    font-weight: 500;
    font-family: 'gill sans';
}

#cfContainer button[type='submit']{
    background-color: #b2c955;
    color: #fff;
    padding: 5px 12px;
    font-size: 18px;
    display: flex;
    align-items: center;
}

/* For superadmin panel */

p.itemTitle{
    color: #9b9b9b;
    font-size: 18px;
}

p.iteminfo{
    font-size: 16px;
}

button.viewMore{
    background-color: #b2c955;
    color: #fff;
    border-radius: 12px;
    padding: 7px 15px;
    cursor: pointer;
}

label#filterLabel, select#cfFilter{
    display: inline-block;
}

label#filterLabel{
    margin-right: 5px;
}

select#cfFilter{
    width: 100px;
    border-radius: 5px;
    padding: 5px 7px;
    appearance: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/complaintFeedback/cfStyle.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,mCAAmC;IACnC,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;AACA;;;IAGI,gBAAgB;AACpB;;AAEA;;IAEI,eAAe;IACf,gBAAgB;IAChB,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,mBAAmB;AACvB;;AAEA,yBAAyB;;AAEzB;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":["#cfContainer{\n    background-color: #fff;\n}\n\n#cfContainer *{\n    font-family: 'gill sans' !important;\n    font-size: 16px !important;\n    font-weight: 500 !important;\n}\n\n#cfContainer label{\n    font-size: 22px;\n}\n\n#cfContainer select{\n    appearance: auto;\n}\n#cfContainer select,\n#cfContainer input[type='text'],\n#cfContainer textarea{\n    padding: 7px 9px;\n}\n\n#cfContainer input[type='text']::placeholder,\n#cfContainer textarea::placeholder{\n    font-size: 16px;\n    font-weight: 500;\n    font-family: 'gill sans';\n}\n\n#cfContainer button[type='submit']{\n    background-color: #b2c955;\n    color: #fff;\n    padding: 5px 12px;\n    font-size: 18px;\n    display: flex;\n    align-items: center;\n}\n\n/* For superadmin panel */\n\np.itemTitle{\n    color: #9b9b9b;\n    font-size: 18px;\n}\n\np.iteminfo{\n    font-size: 16px;\n}\n\nbutton.viewMore{\n    background-color: #b2c955;\n    color: #fff;\n    border-radius: 12px;\n    padding: 7px 15px;\n    cursor: pointer;\n}\n\nlabel#filterLabel, select#cfFilter{\n    display: inline-block;\n}\n\nlabel#filterLabel{\n    margin-right: 5px;\n}\n\nselect#cfFilter{\n    width: 100px;\n    border-radius: 5px;\n    padding: 5px 7px;\n    appearance: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
