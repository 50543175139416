// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-body1{
    background: #f1f7e1 ;
}
.welcome-heading {
    position: absolute;
    top: 112px;
    left: 64px;
    font-size: 59px;
    color: #019961;
    font-weight: 500;
}
.superAdmin-form {
    border-radius: 0px 40px 40px 0px;
    padding: 127px 75px !important;
}
.superAdmin-form h1 {
    padding-bottom: 40px;
}
.web-btn {
    font-size: 20px;
    padding: 5px 0px;
    height: auto !important;
    border-radius: 14px;
}

@media screen and (max-width: 1024px) {
.superAdmin-form {
    padding: 125px 27px !important;
}
}
@media screen and (max-width: 768px) {
    .superAdmin-form {
        margin-top: 42px;
    }
    .sl-img {
        border-radius: 29px;
        width: 100%;
    }
    .superAdmin-form {
        padding: 35px 27px !important;
        border-radius: 40px;
    }
    
}
@media screen and (max-width: 414px) {
.welcome-heading {
    font-size: 3em;
    left: auto;
    margin: auto;
    width: 100%;
    text-align: center;
}
}`, "",{"version":3,"sources":["webpack://./src/components/super-admin/super-admin-login.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,gCAAgC;IAChC,8BAA8B;AAClC;AACA;IACI,oBAAoB;AACxB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;AACA;IACI,8BAA8B;AAClC;AACA;AACA;IACI;QACI,gBAAgB;IACpB;IACA;QACI,mBAAmB;QACnB,WAAW;IACf;IACA;QACI,6BAA6B;QAC7B,mBAAmB;IACvB;;AAEJ;AACA;AACA;IACI,cAAc;IACd,UAAU;IACV,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;AACA","sourcesContent":[".bg-body1{\n    background: #f1f7e1 ;\n}\n.welcome-heading {\n    position: absolute;\n    top: 112px;\n    left: 64px;\n    font-size: 59px;\n    color: #019961;\n    font-weight: 500;\n}\n.superAdmin-form {\n    border-radius: 0px 40px 40px 0px;\n    padding: 127px 75px !important;\n}\n.superAdmin-form h1 {\n    padding-bottom: 40px;\n}\n.web-btn {\n    font-size: 20px;\n    padding: 5px 0px;\n    height: auto !important;\n    border-radius: 14px;\n}\n\n@media screen and (max-width: 1024px) {\n.superAdmin-form {\n    padding: 125px 27px !important;\n}\n}\n@media screen and (max-width: 768px) {\n    .superAdmin-form {\n        margin-top: 42px;\n    }\n    .sl-img {\n        border-radius: 29px;\n        width: 100%;\n    }\n    .superAdmin-form {\n        padding: 35px 27px !important;\n        border-radius: 40px;\n    }\n    \n}\n@media screen and (max-width: 414px) {\n.welcome-heading {\n    font-size: 3em;\n    left: auto;\n    margin: auto;\n    width: 100%;\n    text-align: center;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
